<template>
  <div class="roles-main">
    <div class="header-container">
      <div class="search">
        <el-input
          v-model="request.name"
          size="small"
          style="width: 200px"
          :placeholder="t('fields.name')"
        />
        <el-input
          v-model="request.code"
          size="small"
          style="width: 200px"
          :placeholder="t('fields.code')"
        />
        <el-select
          clearable
          v-model="request.status"
          size="small"
          :placeholder="t('fields.status')"
          class="filter-item"
          style="width: 120px; margin-left: 5px"
        >
          <el-option
            v-for="item in uiControl.status"
            :key="item.key"
            :label="item.displayName"
            :value="item.value"
          />
        </el-select>
        <!-- <el-select
          v-model="request.siteId"
          size="small"
          :placeholder="t('fields.site')"
          class="filter-item"
          style="width: 120px; margin-left: 5px"
        >
          <el-option
            v-for="item in siteList.list"
            :key="item.id"
            :label="item.siteName"
            :value="item.id"
          />
        </el-select> -->
        <el-button
          style="margin-left: 20px"
          icon="el-icon-search"
          size="mini"
          type="success"
          @click="loadPrivilegeInfo"
        >
          {{ t('fields.search') }}
        </el-button>
        <el-button
          icon="el-icon-refresh"
          size="mini"
          type="warning"
          @click="resetQuery()"
        >
          {{ t('fields.reset') }}
        </el-button>
      </div>
      <div class="btn-group">
        <el-button
          icon="el-icon-plus"
          size="mini"
          type="primary"
          v-permission="['sys:privilege:add']"
          @click="showDialog('CREATE')"
        >
          {{ t('fields.add') }}
        </el-button>
      </div>
    </div>
    <el-dialog
      :title="uiControl.dialogTitle"
      v-model="uiControl.dialogVisible"
      append-to-body
      width="700px"
    >
      <el-steps
        class="steps"
        :space="200"
        :active="active"
        finish-status="success"
        align-center
      >
        <el-step :title="t('fields.priviBasicInfo')" @click="goToStep(0)" />
        <el-step :title="t('fields.priviRewardSetup')" @click="goToStep(1)" />
        <el-step :title="t('fields.priviVipSetup')" @click="goToStep(2)" />
        <el-step :title="t('fields.priviPaymentSetup')" @click="goToStep(3)" />
        <el-step :title="t('fields.priviOther')" @click="goToStep(4)" />
      </el-steps>
      <el-form
        ref="privilegeInfoForm"
        :model="form"
        :rules="formRules"
        :inline="true"
        size="small"
        label-width="150px"
      >
        <div v-if="active === 0">
          <el-form-item :label="t('fields.name')" prop="name">
            <el-input v-model="form.name" style="width: 450px" maxlength="100" />
          </el-form-item>
          <el-form-item :label="t('fields.alias')" prop="alias">
            <el-input v-model="form.alias" style="width: 450px" maxlength="100" />
          </el-form-item>
          <el-form-item :label="t('fields.code')" prop="code">
            <el-input v-model="form.code" style="width: 450px" maxlength="50" />
          </el-form-item>
          <el-form-item :label="t('fields.site')" prop="siteId">
            <el-select
              v-model="form.siteId"
              size="small"
              :placeholder="t('fields.site')"
              class="filter-item"
              style="width: 450px"
              default-first-option
              @focus="loadSites"
              @change="changeSite"
            >
              <el-option
                v-for="item in siteList.list"
                :key="item.id"
                :label="item.siteName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="t('fields.startTime')" prop="startTime">
            <el-date-picker
              type="datetime"
              value-format="YYYY-MM-DD HH:mm:ss"
              v-model="form.startTime"
              style="width: 450px"
              :disabled-date="disabledStartDate"
            />
          </el-form-item>
          <el-form-item :label="t('fields.endTime')" prop="endTime">
            <el-date-picker
              type="datetime"
              value-format="YYYY-MM-DD HH:mm:ss"
              v-model="form.endTime"
              style="width: 450px"
              :disabled-date="disabledEndDate"
            />
          </el-form-item>
          <el-form-item :label="t('fields.frequency')" prop="frequency">
            <el-select
              v-model="form.frequency"
              style="width: 250px"
              filterable
              default-first-option
            >
              <el-option
                v-for="f in uiControl.frequency"
                :key="f.key"
                :label="f.displayName"
                :value="f.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            :label="t('fields.bonusDays')"
            prop="bonusDays"
            v-if="
              form.frequency === 'DAILY' && form.triggerType === 'DEPOSITBONUSES'
            "
          >
            <el-checkbox
              v-model="checkboxes.bonusDays.checkAll"
              :indeterminate="checkboxes.bonusDays.isIndeterminate"
              @change="handleBonusDaysCheckAllChange"
            >
              {{ t('fields.checkall') }}
            </el-checkbox>
            <el-checkbox-group
              v-model="selectedBonusDays.bonusDaysChecked"
              @change="handleCheckedChange('BONUSDAYS')"
              style="width: 450px"
            >
              <el-checkbox v-for="v in uiControl.day" :label="v.key" :key="v.key">
                {{ v.displayName }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <!-- <el-form-item :label="t('fields.status')" prop="status">
            <el-radio-group v-model="form.status">
              <el-radio
                v-for="c in uiControl.status"
                :label="c.value"
                :key="c.key"
                v-model="form.status"
              >
                {{ c.displayName }}
              </el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item :label="t('fields.status')" prop="status">
            <el-radio-group
              v-model="form.status"
              style="width: 300px"
            >
              <el-radio-button label="OPEN">
                {{ t('common.status.OPEN') }}
              </el-radio-button>
              <el-radio-button label="CLOSE">
                {{ t('common.status.CLOSE') }}
              </el-radio-button>
              <el-radio-button label="TEST">
                {{ t('common.status.TEST') }}
              </el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item :label="t('fields.triggerType')" prop="triggerType">
            <el-select
              v-model="form.triggerType"
              style="width: 450px"
              filterable
              default-first-option
              @change="onChange($event)"
            >
              <el-option
                v-for="tt in uiControl.triggerType"
                :key="tt.key"
                :label="tt.displayName"
                :value="tt.value"
              />
            </el-select>
          </el-form-item>
        </div>
        <div v-if="active === 1">
          <el-form-item :label="t('fields.bonusType')" prop="bonusType">
            <el-radio-group v-model="form.bonusType">
              <el-radio
                v-for="b in uiControl.bonusType"
                :key="b.key"
                :label="b.value"
              >
                {{ b.displayName }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-row>
            <el-col>
              <el-form-item :label="t('fields.bonusAmount')" prop="bonusAmount">
                <span v-if="form.bonusType === 'FIXED'">
                  $
                  <el-input-number
                    v-model="form.bonusAmount"
                    style="width: 135px"
                    :min="1"
                    :max="999999999999999"
                    :controls="false"
                    @keypress="restrictInput($event)"
                  />
                </span>
                <span v-if="form.bonusType === 'RATIO'">
                  <el-input
                    v-model="form.bonusAmountRatio"
                    style="width: 135px"
                    :maxlength="uiControl.bonusAmountRatioMax"
                    @keypress="restrictDecimalInput($event)"
                  />
                  %
                </span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="t('fields.rolloverType')" prop="rolloverType">
                <el-select
                  v-model="selectedRolloverType"
                  style="width: 100%;"
                  filterable
                  default-first-option
                  @change="checkRolloverType"
                >
                  <el-option
                    v-for="f in uiControl.rolloverType"
                    :key="f.key"
                    :label="f.displayName"
                    :value="f.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col v-if="uiControl.selectedGameTypeRolloverType !== 'GAME_TYPE'" :span="12">
              <el-form-item prop="rollover">
                <el-input-number
                  v-model="uiControl.rollOverAmt"
                  style="width: 145px"
                  :min="0"
                  :max="selectedRolloverType === 'MULTIPLE' ? 100 : 999999999999999"
                  :controls="false"
                  @keypress="restrictInput($event)"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item
              class="is-required"
              :label="t('fields.gameTypeRollover')"
              prop="gameTypeRollover"
            >
              <el-select
                v-model="uiControl.selectedGameTypeRolloverType"
                style="width: 250px"
                filterable
              >
                <el-option
                  v-for="f in uiControl.gameTypeRolloverTypes"
                  :key="f.key"
                  :label="f.displayName"
                  :value="f.value"
                />
              </el-select>
              <div v-if="uiControl.selectedGameTypeRolloverType !== null && uiControl.selectedGameTypeRolloverType !== 'ALL_TYPES'">
                <div v-for="(item, index) in gameTypes" :key="index">
                  <el-select
                    v-model="item.key"
                    size="small"
                    :placeholder="t('fields.gameType')"
                    class="filter-item"
                    style="width: 170px; margin-top: 5px"
                  >
                    <el-option
                      v-for="gameType in uiControl.gameTypeRollover"
                      :key="gameType.key"
                      :label="t(`gameType.${gameType.displayName}`)"
                      :value="gameType.value"
                    />
                  </el-select>
                  <span v-if="uiControl.selectedGameTypeRolloverType === 'GAME_TYPE'">
                    :
                    <el-input-number v-if="item.key" :controls="false" style="width: 170px " v-model="item.value" :min="1" :max="selectedRolloverType === 'MULTIPLE'? 100 : 999999999999999" />
                    <el-input-number v-else :controls="false" style="width: 170px " v-model="item.value" />
                  </span>
                  <el-button
                    v-if="index === gameTypes.length - 1"
                    icon="el-icon-plus"
                    size="mini"
                    type="primary"
                    style="margin-left: 20px"
                    @click="addRollover()"
                    plain
                  >
                    {{ t('fields.add') }}
                  </el-button>
                  <el-button
                    v-else
                    icon="el-icon-remove"
                    size="mini"
                    type="danger"
                    style="margin-left: 20px"
                    @click="delRollover(index)"
                    plain
                  >
                    {{ t('fields.delete') }}
                  </el-button>
                </div>
              </div>
            </el-form-item>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item :label="t('fields.minBalance')" prop="minBalance">
                $
                <el-input-number
                  v-model="form.minBalance"
                  style="width: 145px"
                  :min="0"
                  :controls="false"
                  @keypress="restrictInput($event)"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item :label="t('fields.minDeposit')" prop="depositMin">
                $
                <el-input-number
                  v-model="form.depositMin"
                  style="width: 135px"
                  :min="1"
                  :controls="false"
                  @keypress="restrictInput($event)"
                />
              </el-form-item>
              <el-form-item :label="t('fields.maxBonus')" prop="bonusMax">
                $
                <el-input-number
                  v-model="form.bonusMax"
                  style="width: 135px"
                  :controls="false"
                  @keypress="restrictInput($event)"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div v-if="active === 2">
          <el-form-item label="VIP" prop="vips">
            <el-checkbox
              v-model="checkboxes.vip.checkAll"
              :indeterminate="checkboxes.vip.isIndeterminate"
              @change="handleVIPCheckAllChange"
            >
              {{ t('fields.checkall') }}
            </el-checkbox>
            <el-checkbox-group
              v-model="selectedVIPs.vipChecked"
              @change="handleCheckedChange('VIP')"
              style="width: 300px"
            >
              <el-checkbox v-for="v in vipList.list" :label="v.id" :key="v.id">
                {{ v.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
        <div v-if="active === 3">
          <el-form-item :label="t('fields.paymentType')" prop="payTypes">
            <el-checkbox
              v-model="checkboxes.paymentType.checkAll"
              :indeterminate="checkboxes.paymentType.isIndeterminate"
              @change="handlePaymentTypeCheckAllChange"
            >
              {{ t('fields.checkall') }}
            </el-checkbox>
            <el-checkbox-group
              v-model="selectedPayTypes.payTypeChecked"
              @change="handleCheckedChange('PAYTYPE')"
            >
              <el-checkbox
                v-for="p in paymentTypeList.list"
                :label="p.code"
                :key="p.id"
              >
                {{ p.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </div>
        <div v-if="active === 4">
          <el-form-item
            :label="t('fields.pgroup')"
            prop="pgroup"
            v-if="uiControl.pgroup"
          >
            <el-input
              v-model="form.pgroup"
              style="width: 450px"
              maxlength="100"
            />
          </el-form-item>
          <el-form-item :label="t('fields.param')" prop="param">
            <JsonEditor
              class="editor"
              v-model="editorValue"
              currentMode="code"
              :modeList="[]"
              @update:modelValue="updataModel"
            />
            <div v-if="editorValueError">
              {{ editorValueError }}
            </div>
            <el-input
              v-if="editorValueError"
              type="textarea"
              v-model="form.param"
              :rows="20"
              style="width: 450px; white-space: pre-line"
              placeholder="{'abc':'xyz'}"
              @change="json"
            />
            <el-input
              v-else
              type="hidden"
              v-model="form.param"
              style="width: 450px; white-space: pre-line"
            />
          </el-form-item>
          <el-form-item :label="t('fields.remark')" prop="remark">
            <el-input
              type="textarea"
              v-model="form.remark"
              :rows="6"
              style="width: 350px;"
              maxlength="500"
              show-word-limit
            />
          </el-form-item>
        </div>
        <!-- <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">
            {{ t('fields.cancel') }}
          </el-button>
          <el-button type="primary" @click="submit">
            {{ t('fields.confirm') }}
          </el-button>
        </div> -->
        <div class="dialog-footer">
          <el-button @click="uiControl.dialogVisible = false">{{ t('fields.cancel') }}</el-button>
          <el-button v-if="active > 0" @click="active -= 1">{{ t('fields.priviClickPreviousStep') }}</el-button>
          <el-button
            v-if="active < 4"
            type="primary"
            @click="validateAndNext"
          >
            {{ t('fields.priviClickNextStep') }}
          </el-button>
          <el-button v-if="active === 4" type="primary" @click="submit">{{ t('fields.confirm') }}</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-table
      :data="page.records"
      ref="table"
      row-key="id"
      size="small"
      highlight-current-row
      v-loading="page.loading"
      :empty-text="t('fields.noData')"
    >
      <el-table-column prop="id" :label="t('fields.id')" width="100" />
      <el-table-column prop="name" :label="t('fields.name')" width="200" />
      <el-table-column prop="alias" :label="t('fields.alias')" width="200" />
      <el-table-column
        prop="bonusType"
        :label="t('fields.bonusType')"
        width="150"
      >
        <template #default="scope">
          <span v-for="b in uiControl.bonusType" :key="b.key">
            <span v-if="scope.row.bonusType === b.value">
              {{ b.displayName }}
            </span>
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="bonusAmount"
        :label="t('fields.bonusAmount')"
        width="150"
      >
        <template #default="scope">
          <span v-if="scope.row.bonusType === 'FIXED'">
            $
            <!-- eslint-disable -->
            <span
              v-formatter="{ data: scope.row.bonusAmount, type: 'money' }"
            />
          </span>
          <span v-if="scope.row.bonusType === 'RATIO'">
            {{ (scope.row.bonusAmount * 100).toFixed(2) }} %
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="frequency"
        :label="t('fields.frequency')"
        width="150"
      >
        <template #default="scope">
          <span v-for="f in uiControl.frequency" :key="f.key">
            <span v-if="scope.row.frequency === f.value">
              {{ f.displayName }}
            </span>
          </span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="status" :label="t('fields.status')" width="150">
        <template #default="scope">
          <el-tag v-if="scope.row.status === 'OPEN'" type="success">
            {{ scope.row.status }}
          </el-tag>
          <el-tag v-if="scope.row.status === 'CLOSE'" type="danger">
            {{ scope.row.status }}
          </el-tag>
          <el-tag v-if="scope.row.status === 'TEST'">
            {{ scope.row.status }}
          </el-tag>
        </template>
      </el-table-column> -->
      <el-table-column prop="status" :label="t('fields.status')" min-width="150">
        <template #default="scope">
          <el-radio-group
            v-model="scope.row.status"
            size="mini"
            @change="changePrivilegeStatus(scope.row.id, scope.row.status)"
          >
            <el-radio-button label="OPEN">{{ t('common.status.OPEN') }}</el-radio-button>
            <el-radio-button label="CLOSE">{{ t('common.status.CLOSE') }}</el-radio-button>
            <el-radio-button label="TEST">{{ t('common.status.TEST') }}</el-radio-button>
          </el-radio-group>
        </template>
      </el-table-column>
      <el-table-column prop="site" :label="t('fields.site')" width="120" />
      <el-table-column prop="updateBy" :label="t('fields.updateBy')" />
      <el-table-column prop="updateTime" :label="t('fields.updateTime')">
        <template #default="scope">
          <span v-if="scope.row.updateTime === null">-</span>
          <span
            v-if="scope.row.updateTime !== null"
            v-formatter="{
              data: scope.row.updateTime,
              timeZone: scope.row.timeZone,
              type: 'date',
            }"
          />
        </template>
      </el-table-column>
      <el-table-column
        :label="t('fields.operate')"
        align="right"
        v-if="hasPermission(['sys:privilege:update'])"
      >
        <template #default="scope">
          <el-button
            icon="el-icon-edit"
            size="mini"
            type="success"
            @click="showEdit(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      @current-change="changePage"
      layout="prev, pager, next"
      :page-size="request.size"
      :page-count="page.pages"
      :current-page="request.current"
    />
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, reactive, ref } from 'vue'
import moment from 'moment'
import { required } from '../../../utils/validate'
import { ElMessage } from 'element-plus'
import {
  createPrivilegeInfo,
  updatePrivilegeInfo,
  getPrivilegeInfo,
  updatePrivilegeInfoState,
} from '../../../api/privilege-info'
import { getVipList } from '../../../api/vip'
import { getActivePaymentTypes } from '../../../api/payment-type'
import { getSiteListSimple } from '../../../api/site'
import { hasPermission } from '../../../utils/util'
import { useStore } from '../../../store'
import { TENANT } from '../../../store/modules/user/action-types'
import { useI18n } from 'vue-i18n'
import JsonEditor from 'json-editor-vue3'
import { isXF, isThai } from '@/utils/site'

const { t } = useI18n()
const store = useStore()
const LOGIN_USER_TYPE = computed(() => store.state.user.userType)
const site = ref(null)
// const rollover = ref([])
const gameTypes = ref([])
const selectedRolloverType = ref();
const privilegeInfoForm = ref(null)
const siteList = reactive({
  list: [],
})
const active = ref(0)

const uiControl = reactive({
  dialogVisible: false,
  dialogTitle: '',
  dialogType: 'CREATE',
  editBtn: true,
  removeBtn: true,
  frequency: [
    { key: 1, displayName: 'Everytime', value: 'EVERYTIME' },
    { key: 2, displayName: 'Daily', value: 'DAILY' },
    { key: 3, displayName: 'Weekly', value: 'WEEKLY' },
    { key: 4, displayName: 'Monthly', value: 'MONTHLY' },
    { key: 5, displayName: 'Yearly', value: 'YEARLY' },
    { key: 6, displayName: 'Only Once', value: 'ONLYONE' },
  ],
  bonusType: [
    { key: 1, displayName: 'Fixed', value: 'FIXED' },
    { key: 2, displayName: 'Ratio', value: 'RATIO' },
  ],
  rolloverType: [
    { key: 1, displayName: t('fields.rollOverAmt'), value: 'AMOUNT' },
    { key: 2, displayName: t('fields.rollOverMulti'), value: 'MULTIPLE' },
  ],
  triggerType: [
    { key: 1, displayName: 'Deposit', value: 'DEPOSITBONUSES' },
    { key: 2, displayName: 'Manual', value: 'MANUAL' },
    { key: 3, displayName: 'Free', value: 'FREE' },
    { key: 4, displayName: 'Other', value: 'OTHER' },
    { key: 5, displayName: 'Time', value: 'TIME' },
    { key: 6, displayName: 'Multiple', value: 'MULTIPLE' },
    { key: 7, displayName: 'PGroup', value: 'PGROUP' },
    { key: 8, displayName: 'Sequence', value: 'SEQUENCE' },
  ],
  status: [
    { key: 1, displayName: 'Open', value: 'OPEN' },
    { key: 2, displayName: 'Close', value: 'CLOSE' },
    { key: 3, displayName: 'Test', value: 'TEST' },
  ],
  day: [
    { key: 1, displayName: t('week.monday'), value: 1 },
    { key: 2, displayName: t('week.tuesday'), value: 2 },
    { key: 3, displayName: t('week.wednesday'), value: 3 },
    { key: 4, displayName: t('week.thursday'), value: 4 },
    { key: 5, displayName: t('week.friday'), value: 5 },
    { key: 6, displayName: t('week.saturday'), value: 6 },
    { key: 7, displayName: t('week.sunday'), value: 7 },
  ],
  gameTypeRollover: [
    { key: 1, displayName: 'SLOT', value: 'slot' },
    { key: 2, displayName: 'LIVE', value: 'live' },
    { key: 3, displayName: 'FISH', value: 'fish' },
    { key: 4, displayName: 'SPORT', value: 'sport' },
    { key: 5, displayName: 'ESPORT', value: 'esport' },
    { key: 6, displayName: 'POKER', value: 'poker' },
    { key: 7, displayName: 'LOTTERY', value: 'lottery' },
    { key: 8, displayName: 'CASUAL', value: 'casual' },
    { key: 9, displayName: 'HOTELCASINO', value: 'hotelcasino' },
  ],
  gameTypeRolloverTypes: [
    { key: 1, displayName: t('gameTypeRolloverSetting.anyTypes'), value: 'ALL_TYPES' },
    { key: 2, displayName: t('gameTypeRolloverSetting.specifyTypes'), value: 'SPECIFY_TYPES' },
    { key: 3, displayName: t('gameTypeRolloverSetting.excludeTypes'), value: 'EXCLUDE_TYPES' },
    { key: 4, displayName: t('gameTypeRolloverSetting.specifyGameType'), value: 'GAME_TYPE' },
  ],
  bonusAmountRatioMax: 15,
  pgroup: false,
  selectedGameTypeRolloverType: null,
  rollOverAmt: null,
  isNewRollover: true,
  oldRollOver: {
    rollover: 0,
    gameTypeRollover: null,
    gameLists: [],
    selectType: null
  }
})
const page = reactive({
  pages: 0,
  records: [],
  loading: false,
})

const request = reactive({
  size: 30,
  current: 1,
  name: null,
  code: null,
  status: null,
  siteId: null,
})

const form = reactive({
  id: null,
  alias: null,
  name: null,
  code: null,
  status: 'OPEN',
  frequency: null,
  startTime: null,
  endTime: null,
  rollover: null,
  gameTypeRollover: null,
  minBalance: null,
  bonusType: null,
  bonusAmount: null,
  bonusAmountRatio: null,
  triggerType: null,
  vips: null,
  payTypes: null,
  depositMin: null,
  bonusMax: null,
  siteId: null,
  param: null,
  remark: null,
  pgroup: null,
  bonusDays: null,
})

const formRules = reactive({
  name: [required(t('message.validateNameRequired'))],
  code: [required(t('message.validateCodeRequired'))],
  status: [required(t('message.validateStatusRequired'))],
  frequency: [required(t('message.validateFrequencyRequired'))],
  startTime: [required(t('message.validateStartTimeRequired'))],
  endTime: [required(t('message.validateEndTimeRequired'))],
  // rollover: [required(t('message.validateRolloverRequired'))],
  minBalance: [required(t('message.validateMinBalanceRequired'))],
  bonusType: [required(t('message.validateBonusTypeRequired'))],
  bonusAmount: [required(t('message.validateBonusAmountRequired'))],
  bonusAmountRatio: [required(t('message.validateBonusAmountRatioRequired'))],
  triggerType: [required(t('message.validateTriggerTypeRequired'))],
  vips: [required(t('message.validateVIPRequired'))],
  payTypes: [required(t('message.validatePayTypeRequired'))],
  depositMin: [required(t('message.validateMinDepositRequired'))],
  siteId: [required(t('message.validateSiteRequired'))],
  // gameTypeRollover: [required(t('message.validateRolloverRequired'))],
})

const vipList = reactive({
  list: [],
})
const paymentTypeList = reactive({
  list: [],
})

const excludePlatformGame = ref("");

const selectedVIPs = reactive({ vipChecked: [] })
const selectedPayTypes = reactive({ payTypeChecked: [] })
const selectedBonusDays = reactive({ bonusDaysChecked: [] })

const checkboxes = reactive({
  paymentType: {
    checkAll: ref(false),
    isIndeterminate: ref(false),
  },
  vip: {
    checkAll: ref(false),
    isIndeterminate: ref(false),
  },
  bonusDays: {
    checkAll: ref(false),
    isIndeterminate: ref(false),
  },
})

function disabledStartDate(time) {
  if (form.endTime !== null) {
    const changedDate = form.endTime.replace(/(..)\/(..)\/(....)/, '$3-$2-$1')
    var date = new Date(changedDate)
    return (
      time.getTime() <= moment(Date.now()).subtract(1, 'days') ||
      time.getTime() >= moment(date)
    )
  }
  return time.getTime() <= moment(Date.now()).subtract(1, 'days')
}

function disabledEndDate(time) {
  if (form.startTime !== null) {
    const changedDate = form.startTime.replace(/(..)\/(..)\/(....)/, '$3-$2-$1')
    var date = new Date(changedDate)
    return time.getTime() <= date.getTime()
  }
  return time.getTime() <= Date.now()
}

function resetQuery() {
  request.name = null
  request.code = null
  request.status = null
  request.siteId = store.state.user.siteId
}

function handleCheckedChange(type) {
  if (type === 'VIP') {
    form.vips = JSON.stringify(selectedVIPs.vipChecked.join(','))
  } else if (type === 'PAYTYPE') {
    form.payTypes = JSON.stringify(selectedPayTypes.payTypeChecked.join(','))
  } else if (type === 'BONUSDAYS') {
    form.bonusDays = JSON.stringify(
      selectedBonusDays.bonusDaysChecked.join(',')
    )
  }
  handleIndividualCheckChange()
}

const handleVIPCheckAllChange = val => {
  selectedVIPs.vipChecked = []
  if (val) {
    vipList.list.forEach(vip => {
      selectedVIPs.vipChecked.push(vip.id)
    })
  }
  handleCheckedChange('VIP')
}

const handlePaymentTypeCheckAllChange = val => {
  selectedPayTypes.payTypeChecked = []
  if (val) {
    paymentTypeList.list.forEach(paymentType => {
      selectedPayTypes.payTypeChecked.push(paymentType.code)
    })
  }
  handleCheckedChange('PAYTYPE')
}

const handleBonusDaysCheckAllChange = val => {
  selectedBonusDays.bonusDaysChecked = []
  if (val) {
    uiControl.day.forEach(day => {
      selectedBonusDays.bonusDaysChecked.push(day.key)
    })
  }
  handleCheckedChange('BONUSDAYS')
}

const cachedGameTypes = ref([]);
const cachedUIAmt = ref([]);
const checkRolloverType = () => {
  if (selectedRolloverType.value === 'MULTIPLE') {
    cachedUIAmt.value = uiControl.rollOverAmt
    if (uiControl.rollOverAmt > 100) {
      uiControl.rollOverAmt = 100
    } else {
      uiControl.rollOverAmt = null
    }
    cachedGameTypes.value = gameTypes.value.map(type => ({ ...type }));
    gameTypes.value.forEach(type => {
      if (type.value > 100) {
        type.value = 100;
      }
    });
  } else {
    uiControl.isNewRollover = true;
    // gameTypes.value = cachedGameTypes.value.map(type => ({ ...type }));
    if (cachedGameTypes.value.length > 0) {
      gameTypes.value = cachedGameTypes.value.map(type => ({ ...type }));
    }
    if (cachedUIAmt.value) {
      uiControl.rollOverAmt = cachedUIAmt.value
    }
  }
}

function changeSite(siteId) {
  if (isXF(siteId)) {
    form.minBalance = 5
  } else if (isThai(siteId)) {
    form.minBalance = 20
  } else {
    form.minBalance = 0
  }
  getVipBySiteId(siteId)
}

async function getVipBySiteId(siteId) {
  await loadVips()
  vipList.list = vipList.list.filter(vip => vip.siteId === siteId)
}

async function loadPrivilegeInfo() {
  page.loading = true
  const { data: ret } = await getPrivilegeInfo(request)
  page.pages = ret.pages
  ret.records.forEach(data => {
    data.timeZone =
      store.state.user.sites.find(e => e.id === data.siteId) !== undefined
        ? store.state.user.sites.find(e => e.id === data.siteId).timeZone
        : null
  })
  page.records = ret.records
  page.loading = false
}

async function loadVips() {
  const { data: vip } = await getVipList()
  vipList.list = vip
}

async function loadActivePaymentType() {
  const { data: type } = await getActivePaymentTypes()
  paymentTypeList.list = type
}

async function loadSites() {
  const { data: site } = await getSiteListSimple()
  siteList.list = site
}

function changePage(page) {
  request.current = page
  loadPrivilegeInfo()
}

function showDialog(type) {
  clearCheckAll()
  if (privilegeInfoForm.value) {
    privilegeInfoForm.value.resetFields(); // Reset form fields
  }

  if (type === 'CREATE') {
    active.value = 0
    if (privilegeInfoForm.value) {
      // privilegeInfoForm.value.resetFields()
      selectedVIPs.vipChecked = []
      selectedPayTypes.payTypeChecked = []
      selectedBonusDays.bonusDaysChecked = []
    }
    form.frequency = uiControl.frequency[0].value
    form.bonusType = uiControl.bonusType[0].value
    form.triggerType = uiControl.triggerType[0].value
    form.siteId = siteList.list[0].id
    selectedRolloverType.value = uiControl.rolloverType[0].value
    uiControl.rollOverAmt = null
    changeSite(form.siteId)
    gameTypes.value = []
    uiControl.selectedGameTypeRolloverType = null
    addRollover()
    uiControl.pgroup = false
    uiControl.dialogTitle = t('fields.addPrivilegeInfo')
    uiControl.isNewRollover = true;

    form.alias = null
    form.name = null
    form.code = null
    form.id = null
    form.startTime = null
    form.endTime = null
    form.rollover = null
    form.gameTypeRollover = null
    form.minBalance = null
    form.bonusAmount = null
    form.bonusAmountRatio = null
    form.vips = null
    form.payTypes = null
    form.depositMin = null
    form.bonusMax = null
    form.param = null
    form.remark = null
    form.pgroup = null
    form.bonusDays = null
  } else if (type === 'EDIT') {
    active.value = 0
    // privilegeInfoForm.value.resetFields();
    uiControl.dialogTitle = t('fields.editPrivilegeInfo')
  }
  uiControl.dialogType = type
  uiControl.dialogVisible = true
  editorValue = ''
  editorValueError = ''
  nextTick(() => {
    removeJsonEditorElement()
  })
}

async function showEdit(privilegeInfo) {
  selectedVIPs.vipChecked = []
  selectedPayTypes.payTypeChecked = []
  selectedBonusDays.bonusDaysChecked = []
  uiControl.selectedGameTypeRolloverType = null
  showDialog('EDIT')
  await getVipBySiteId(privilegeInfo.siteId)
  await nextTick(() => {
    for (const key in privilegeInfo) {
      if (Object.keys(form).find(k => k === key)) {
        if (privilegeInfo.bonusType === 'RATIO' && key === 'bonusAmount') {
          form.bonusAmountRatio = privilegeInfo[key] * 100
          form.bonusAmount = 1
        } else if (
          privilegeInfo.bonusType === 'FIXED' &&
          key === 'bonusAmount'
        ) {
          form[key] = privilegeInfo[key]
          form.bonusAmountRatio = 1
        } else {
          form[key] = privilegeInfo[key]

          if (key === 'param') {
            try {
              editorValue = JSON.parse(form[key])
            } catch (error) {
              editorValueError =
                error.length > 30 ? error.substr(0, 30) + '..' : error
            }
          }
        }
      }
    }

    if (privilegeInfo.triggerType === 'PGROUP') {
      uiControl.pgroup = true
    } else {
      uiControl.pgroup = false
    }

    const vipArr = JSON.parse(JSON.stringify(form.vips)).split(',')
    vipArr.forEach(element => {
      selectedVIPs.vipChecked.push(parseInt(element))
    })

    const payTypeArr = JSON.parse(JSON.stringify(form.payTypes)).split(',')
    payTypeArr.forEach(element => {
      selectedPayTypes.payTypeChecked.push(element)
    })
    if (form.bonusDays && form.bonusDays !== '') {
      const bonusDayArr = JSON.parse(JSON.stringify(form.bonusDays)).split(',')
      bonusDayArr.forEach(element => {
        selectedBonusDays.bonusDaysChecked.push(parseInt(element))
      })
    }
    handleIndividualCheckChange()
    gameTypes.value = []
    uiControl.isNewRollover = false;
    if (form.gameTypeRollover) {
      const gameTypeRollover = JSON.parse(form.gameTypeRollover)
      const string = gameTypeRollover.rolloverType;
      const parts = string ? string.split('_') : "";
      var type = parts ? parts.slice(2).join('_') : "";
      if (!(gameTypeRollover && 'gameTypes' in gameTypeRollover)) {
        type = "GAME_TYPE";
      }
      if (gameTypeRollover.newRollover) {
        uiControl.isNewRollover = true;
        selectedRolloverType.value = parts[1];
      } else {
        uiControl.isNewRollover = false;
        uiControl.oldRollOver.gameTypeRollover = gameTypeRollover;
        uiControl.oldRollOver.rollover = form.rollover;

        selectedRolloverType.value = "MULTIPLE";
        uiControl.rollOverAmt = form.rollover;
      }

      // let specifyType = false;
      let gameType = false;
      Object.entries(gameTypeRollover).forEach(([key, value]) => {
        if (key === 'rollover') {
          uiControl.rollOverAmt = value; // Set rollover amount
        } else if (key === 'excludePlatformGame') {
          excludePlatformGame.value = value;
        } else if (key !== 'rolloverType' && key !== 'newRollover' && key !== 'gameTypes' && key !== 'excludePlatformGame' && key !== 'excludeTypes') {
          gameTypes.value.push({ key, value });
        } else if (key === 'gameTypes' && Array.isArray(value)) {
          value.forEach(type => {
            gameTypes.value.push({ key: type, value: null }); // Add each game type to gameTypes array
          });
          gameType = true;
        }
      })
      if (type) {
        uiControl.selectedGameTypeRolloverType = type
      } else if (gameType) {
        uiControl.selectedGameTypeRolloverType = 'GAME_TYPE'
      }
      if (uiControl.isNewRollover === false && (string === "ANY_TYPES" || !string)) {
        if (gameTypeRollover.esport || gameTypeRollover.lottery || gameTypeRollover.sport || gameTypeRollover.slot || gameTypeRollover.casino || gameTypeRollover.casual || gameTypeRollover.poker || gameTypeRollover.fish) {
          uiControl.selectedGameTypeRolloverType = 'GAME_TYPE';
          uiControl.isNewRollover = true;
          uiControl.oldRollOver.selectType = 'ALL_TYPES';
          gameTypes.value.forEach((game) => {
            if (game.key === 'slot') {
              game.value = parseInt(gameTypeRollover.slot)
            }
            if (game.key === 'sport') {
              game.value = parseInt(gameTypeRollover.sport)
            }
            if (game.key === 'casino') {
              game.value = parseInt(gameTypeRollover.casino)
            }
            if (game.key === 'lottery') {
              game.value = parseInt(gameTypeRollover.lottery)
            }
            if (game.key === 'esport') {
              game.value = parseInt(gameTypeRollover.esport)
            }
            if (game.key === 'casual') {
              game.value = parseInt(gameTypeRollover.casual)
            }
            if (game.key === 'fish') {
              game.value = parseInt(gameTypeRollover.fish)
            }
            if (game.key === 'poker') {
              game.value = parseInt(gameTypeRollover.poker)
            }
          })
        } else {
          uiControl.oldRollOver.selectType = 'ALL_TYPES';
          uiControl.selectedGameTypeRolloverType = 'ALL_TYPES'
        }
        addRollover()
      } else if (uiControl.isNewRollover === false && (string === "EXCLUDE_TYPES")) {
        uiControl.selectedGameTypeRolloverType = 'EXCLUDE_TYPES';
        uiControl.oldRollOver.selectType = 'EXCLUDE_TYPES';

        const excludeItem = gameTypeRollover.excludeTypes;
        gameTypes.value.push({
          key: excludeItem,
          value: form.rollover,
        })
        uiControl.oldRollOver.gameLists = JSON.parse(JSON.stringify(gameTypes.value));
      } else if (uiControl.isNewRollover === false && (string === "SPECIFY_TYPE")) {
        uiControl.selectedGameTypeRolloverType = 'SPECIFY_TYPES';

        uiControl.oldRollOver.selectType = 'SPECIFY_TYPES';
        uiControl.oldRollOver.gameLists = JSON.parse(JSON.stringify(gameTypes.value))
      } else {
        addRollover()
      }
    } else {
      addRollover()
    }
  })
}

function handleCategoryChange(selectedList, checkboxData, dataList) {
  const selectedCount = selectedList.filter(el => dataList.includes(el)).length
  const listCount = dataList.length
  checkboxData.checkAll = selectedCount === listCount
  checkboxData.isIndeterminate = selectedCount > 0 && selectedCount < listCount
}

function handleIndividualCheckChange() {
  const vipIds = [...new Set(vipList.list.map(el => el.id))]
  handleCategoryChange(selectedVIPs.vipChecked, checkboxes.vip, vipIds)
  const paymentCodes = [...new Set(paymentTypeList.list.map(el => el.code))]
  handleCategoryChange(
    selectedPayTypes.payTypeChecked,
    checkboxes.paymentType,
    paymentCodes
  )
  const bonusDays = [...new Set(uiControl.day.map(el => el.value))]
  handleCategoryChange(
    selectedBonusDays.bonusDaysChecked,
    checkboxes.bonusDays,
    bonusDays
  )
}

function clearCheckAll() {
  checkboxes.vip.checkAll = false
  checkboxes.vip.isIndeterminate = false
  checkboxes.paymentType.checkAll = false
  checkboxes.paymentType.isIndeterminate = false
  checkboxes.bonusDays.checkAll = false
  checkboxes.bonusDays.isIndeterminate = false
}

/**
 * 新增公告
 */
function create() {
  if (!uiControl.selectedGameTypeRolloverType) {
    ElMessage({ message: t('message.validateGameRolloverRequired'), type: 'error' })
    return;
  }
  if (validateGameRollOverType()) {
    ElMessage({ message: t('message.validateGameRolloverSelectRequired'), type: 'error' })
    return;
  }

  if (form.vips === null) {
    ElMessage({ message: t('message.validateVipSettingRequired'), type: 'error' })
    return;
  }

  if (form.payTypes === null) {
    ElMessage({ message: t('message.validatePayementTypesSettingRequired'), type: 'error' })
    return;
  }

  privilegeInfoForm.value.validate(async valid => {
    if (valid) {
      if (form.bonusType === 'RATIO') {
        form.bonusAmount = form.bonusAmountRatio / 100
      }
      form.vips = form.vips.replace(/['"]+/g, '')
      form.payTypes = form.payTypes.replace(/['"]+/g, '')
      if (
        form.bonusDays &&
        form.frequency === 'DAILY' &&
        form.triggerType === 'DEPOSITBONUSES'
      ) {
        form.bonusDays = form.bonusDays.replace(/['"]+/g, '')
      } else {
        form.bonusDays = ''
      }
      form.gameTypeRollover = constructRollover()
      await createPrivilegeInfo(form)
      uiControl.dialogVisible = false
      await loadPrivilegeInfo()
      ElMessage({ message: t('message.addSuccess'), type: 'success' })
    }
  })
}

function validateGameRollOverType () {
  const validate = false;
  // console.log(uiControl.selectedGameTypeRolloverType);
  if (uiControl.selectedGameTypeRolloverType !== 'ANY_TYPES' && uiControl.selectedGameTypeRolloverType !== "ALL_TYPES") {
    const count = gameTypes.value.filter((item) => item.key !== '').length;
    if (count === 0) {
      return true;
    }
  }
  return validate;
}

/**
 * 编辑公告
 */
function edit() {
  if (!uiControl.selectedGameTypeRolloverType) {
    ElMessage({ message: t('message.validateGameRolloverRequired'), type: 'error' })
    return;
  }
  if (validateGameRollOverType()) {
    ElMessage({ message: t('message.validateGameRolloverSelectRequired'), type: 'error' })
    return;
  }

  if (form.vips === null) {
    ElMessage({ message: t('message.validateVipSettingRequired'), type: 'error' })
    return;
  }

  if (form.payTypes === null) {
    ElMessage({ message: t('message.validatePayementTypesSettingRequired'), type: 'error' })
    return;
  }

  privilegeInfoForm.value.validate(async valid => {
    if (valid) {
      if (form.bonusType === 'RATIO') {
        form.bonusAmount = form.bonusAmountRatio / 100
      }
      form.vips = form.vips.replace(/['"]+/g, '')
      form.payTypes = form.payTypes.replace(/['"]+/g, '')
      if (
        form.bonusDays &&
        form.frequency === 'DAILY' &&
        form.triggerType === 'DEPOSITBONUSES'
      ) {
        form.bonusDays = form.bonusDays.replace(/['"]+/g, '')
      } else {
        form.bonusDays = ''
      }
      form.gameTypeRollover = constructRollover()
      await updatePrivilegeInfo(form)
      uiControl.dialogVisible = false
      await loadPrivilegeInfo()
      ElMessage({ message: t('message.editSuccess'), type: 'success' })
    }
  })
}

function submit() {
  if (uiControl.dialogType === 'CREATE') {
    create()
  } else if (uiControl.dialogType === 'EDIT') {
    edit()
  }
}

function restrictInput(event) {
  var charCode = event.which ? event.which : event.keyCode
  if (charCode < 48 || charCode > 57) {
    event.preventDefault()
  }
}

function restrictDecimalInput(event) {
  var charCode = event.which ? event.which : event.keyCode
  if (
    (charCode < 48 || charCode > 57) &&
    charCode !== 46 &&
    form.bonusAmountRatio.toString().indexOf('.') > -1
  ) {
    event.preventDefault()
  }

  if (
    form.bonusAmountRatio !== null &&
    form.bonusAmountRatio.toString().indexOf('.') > -1
  ) {
    if (form.bonusAmountRatio.split('.')[1].length > 1) {
      event.preventDefault()
    }
    uiControl.bonusAmountRatioMax = 16
  } else {
    uiControl.bonusAmountRatioMax = 15
  }
}

function json() {
  if (form.param) {
    nextTick(() => {
      form.param = JSON.stringify(JSON.parse(form.param), null, '\t')
    })
  }
}

function onChange(e) {
  if (e === 'PGROUP') {
    uiControl.pgroup = true
  } else {
    uiControl.pgroup = false
  }
}

function addRollover() {
  gameTypes.value.push({
    key: '',
    value: '',
  })
}

function delRollover(index) {
  gameTypes.value.splice(index, 1)
}

function compareOldGameLists () {
  if (uiControl.oldRollOver.gameLists.length !== gameTypes.value.length) {
    return false;
  }
  if (JSON.stringify(uiControl.oldRollOver.gameLists) !== JSON.stringify(gameTypes.value)) {
    return false;
  }
  return true;
}

function constructRollover() {
  if (uiControl.isNewRollover === false && uiControl.oldRollOver.rollover === uiControl.rollOverAmt &&
    (!uiControl.oldRollOver.gameLists.length || (uiControl.oldRollOver.gameLists.length && compareOldGameLists())) &&
    (!uiControl.oldRollOver.selectType || (uiControl.oldRollOver.selectType === uiControl.selectedGameTypeRolloverType))
  ) {
    form.rollover = uiControl.rollOverAmt;
    return JSON.stringify(uiControl.oldRollOver.gameTypeRollover);
  }

  const json = { newRollover: true };
  if (uiControl.selectedGameTypeRolloverType === 'GAME_TYPE') {
    json.rolloverType = 'INDIVIDUAL_' + selectedRolloverType.value + '_SPECIFY_TYPES'
    Object.values(gameTypes.value).forEach(item => {
      if (item.key) {
        json[item.key] = item.value;
      }
    });
  } else {
    json.rolloverType = 'TOTAL_' + selectedRolloverType.value + '_' + uiControl.selectedGameTypeRolloverType
    const excludeTypes = [];
    Object.values(gameTypes.value).forEach(item => {
      if (item.key) {
        excludeTypes.push(item.key);
      }
    });

    json.gameTypes = excludeTypes;
  }

  if (excludePlatformGame.value) {
    json.excludePlatformGame = excludePlatformGame.value;
  }
  //  else if (uiControl.selectedGameTypeRolloverType === 'EXCLUDE_TYPES' || uiControl.selectedGameTypeRolloverType === 'SPECIFY_TYPE') {
  //   const excludeTypes = []
  //   Object.values(gameTypes.value).forEach(item => {
  //     if (item.key) {
  //       excludeTypes.push(item.key)
  //     }
  //   })
  //   json.gameTypes = excludeTypes
  //   json.rolloverType = 'TOTAL_' + selectedRolloverType.value + '_' + uiControl.selectedGameTypeRolloverType
  // } else {
  //   json.rolloverType = 'TOTAL_' + selectedRolloverType.value + '_' + uiControl.selectedGameTypeRolloverType
  // }
  if (uiControl.selectedGameTypeRolloverType !== 'GAME_TYPE') {
    json.rollover = uiControl.rollOverAmt
  }
  form.rollover = uiControl.rollOverAmt
  return JSON.stringify(json)
}

let editorValue = ref({})
let editorValueError = ref(null)

const updataModel = val => {
  form.param = JSON.stringify(val, null, '\t')
  editorValue = val
}

function removeJsonEditorElement() {
  const classesToRemove = [
    'jsoneditor-poweredBy',
    'jsoneditor-sort',
    'jsoneditor-transform',
    'jsoneditor-undo',
    'jsoneditor-redo',
    'jsoneditor-repair',
  ]
  classesToRemove.forEach(className => {
    const elements = document.getElementsByClassName(className)
    Array.from(elements).forEach(element => {
      if (element.parentNode) {
        element.parentNode.removeChild(element)
      }
    })
  })
}

async function changePrivilegeStatus(id, status) {
  await updatePrivilegeInfoState(id, status)
  ElMessage({ message: t('message.updateSuccess'), type: 'success' })
  await loadPrivilegeInfo()
}

function validateAndNext() {
  privilegeInfoForm.value.validate(async valid => {
    if (valid) {
      active.value += 1;
    }
  });
}

function goToStep(index) {
  privilegeInfoForm.value.validate(async valid => {
    if (valid) {
      active.value = index; // Navigate to the clicked step if valid
    }
  });
}

onMounted(async () => {
  await loadSites()
  request.siteId = store.state.user.siteId
  if (LOGIN_USER_TYPE.value === TENANT.value) {
    site.value = siteList.list.find(
      s => s.siteName === store.state.user.siteName
    )
    request.siteId = site.value.id
  }
  await loadPrivilegeInfo()
  await loadActivePaymentType()
})
</script>

<style rel="stylesheet/scss" lang="scss">
.header-container {
  margin-bottom: 10px;
}

.search {
  display: flex;
  justify-content: flex-start;
}

.btn-group {
  margin-top: 15px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.el-table--enabled-row-transition .el-table__body td.el-table__cell {
  padding: 4px 0;
}

.el-input-number:deep(.el-input__inner) {
  text-align: left;
}

.full-screen {
  right: 20px !important;
}

.el-radio-button--mini .el-radio-button__inner{
  padding: 7px 10px !important;
}

.steps {
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
}
</style>
